import React, { Component } from 'react';
import './Features.css';

class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        let features;

        if (this.props.english) {
            features = (
                <div className="featuresContainer">
                    <h2>Features</h2>
                        <div className="featuresContentContainer">
                        <div className="featuresItem">
                            <p>Hosting</p>
                        </div>
                        <div className="featuresItemInnerLeft">
                            <p>Support</p>
                        </div>
                        <div className="featuresItemInnerRight">
                            <p>SEO</p>
                        </div>
                        </div>
                </div>
            )
        } else {
            features = (
                <div className="featuresContainer">
                    <h2>Privalumai</h2>
                        <div className="featuresContentContainer">
                        <div className="featuresItem">
                            <p>Talpinimas</p>
                        </div>
                        <div className="featuresItemInnerLeft">
                            <p>Palaikymas</p>
                        </div>
                        <div className="featuresItemInnerRight">
                            <p>SEO</p>
                        </div>
                        </div>
                </div>
            )
        }
      
        return (
            <div>
                {features}
            </div>
        );
    }
}

export default Features;