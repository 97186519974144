import React, { Component } from 'react';
import Image1 from '../Images/Image1';
import Image2 from '../Images/Image2';
import Image3 from '../Images/Image3';
import Image6 from '../Images/ImageMobile';
import './Design.css';

class Websites extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        let websites;

        if (this.props.english) {
            websites = (
                <div className="contentContainerDesign">
                    <Image6/>
                        <div className="contentTextDesign">
                            <p>Smartphones are quite popular. I develop custom <b>iPhone and Android apps. </b>
                                I also make sure your app finds it's way to the app store and receives all of the upgrades<b className="designDot">.</b></p>
                        </div>
                    <div className="designImageContainer">
                        <Image1/>
                        <Image2/>
                        <Image3/>
                    </div>
                </div>
            )
        } else {
            websites = (
                <div className="contentContainerDesign">
                     <Image6/>
                        <div className="contentTextDesign">
                            <p>Išmanieji telefonai yra gan populiarus. Pagal užsakyma programuoju <b>iPhone ir Android apsus. </b>
                                Pasirūpinu, kad juos būtų galima parsisiųsti tiesiai iš programėlių parduotuvės, ir gautų visus reikiamus atnaujinimus<b className="designDot">.</b></p>
                        </div>
                    <div className="designImageContainer">
                        <Image1/>
                        <Image2/>
                        <Image3/>
                    </div>
                </div>
            )
        }
      
        return (
            <div>
                {websites}
            </div>
        );
    }
}

export default Websites;