import React, { Component } from 'react';
import './Websites.css';
import Image4 from '../Images/Imagewww';

class Websites extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        let websites;

        if (this.props.english) {
            websites = (
                <div className="contentContainerWebsites">
                    <Image4/>
                        <div className="contentTextWebsites">
                            <p>I build custom, secure and responsive <b>web apps</b>, provide full support and take care of the hosting<b className="websitesDot">.</b></p>
                        </div>
                </div>
            )
        } else {
            websites = (
                <div className="contentContainerWebsites">
                    <Image4/>
                        <div className="contentTextWebsites">
                            <p>Pagal užsakymą kuriu saugias <b>tinklines aplikacijas</b>, prisitaikančias prie ekrano dydžio, joms suteikiu palaikymą ir pasirūpinu talpinimu<b className="websitesDot">.</b></p>
                        </div>
                </div>
            )
        }
      
        return (
            <div>
                {websites}
            </div>
        );
    }
}

export default Websites;