import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'
import './Notifications.css';

const NotificationContact = (props) => {
  const [state, toggle] = useState(true)
  const { x } = useSpring({ from: { x: 0 }, x: state ? 1 : 0, config: { duration: 1200 } });

    let text;
    
    if (props.english) {
        text = <div className="notificationContact-title">
            Contact! 
            <br/>
            e-mail: 
            <br/>
            <a href="mailto: justis@punkt.lt< ">justis@punkt.lt</a>
            <br/>
            phone: 
            <br/>
            <a href="tel: +37060102741">+370 601 02741</a>
        </div>
    }

    if (!props.english) {
        text = <div className="notificationContact-title">
            Susisiekite!
            <br/>
            e-paštas:
            <br/>
            <a href="mailto: justis@punkt.lt">justis@punkt.lt</a>
            <br/>
            telefonas: 
            <br/>
            <a href="tel: +37060102741">+370 601 02741</a>
        </div>
    }

  return (
    <div className="notificationContact-container">
      {state ? 
      <div onClick={() => toggle(!state)}>
        <animated.div
          style={{
            opacity: x.interpolate({ range: [0, 1], output: [0.0, 1] }),
            transform: x
            .interpolate({
              range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
              output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
            })
            .interpolate(x => `scale(${x})`)
          }}>     
          <div className="notificationContact">
            {text}
              </div>
          </animated.div>
        </div> 
    : <div onClick={() => toggle(!state)}> 
        <animated.div
          style={{
            opacity: x.interpolate({ range: [0, 1], output: [1, 0] }),
            transform: x
            .interpolate({
              range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
              output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
            })
            .interpolate(x => `scale(${x})`)
          }}>     
          <div className="notificationContact">
          {text}
              </div>
          </animated.div>
        </div>
    }
    </div>
  )
}

export default NotificationContact;