import React, { Component } from 'react';
import './Apps.css';
import Image5 from '../Images/ImageUI';

class Apps extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
      
        let apps;

        if (this.props.english) {
            apps = (
                <div className="contentContainerApps">
                    <Image5/>
                    <div className="contentTextApps">
                        <p>I design and develop clear and smooth <b>user interfaces</b> for mobile and web-based apps<b className="appsDot">.</b></p>
                    </div>
            </div>
            )
        } else {
            apps = (
                <div className="contentContainerApps">
                    <Image5/>
                    <div className="contentTextApps">
                        <p>Kuriu ir programuoju aiškias ir sklandžias <b>grafines sąsajas</b> tinklinėms ir mobiliosioms aplikacijoms<b className="appsDot">.</b></p>
                    </div>
            </div>
            )
        }

        return (
            <div>
               {apps}
            </div>
        );
    }
}

export default Apps;