import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
var config = {
    apiKey: " AIzaSyBJ45EAoYzhq9keFBK9d1OrGMzetTgOWM8 ",
    authDomain: "punktorders.firebaseapp.com",
    databaseURL: "https://punktorders.firebaseio.com/",
    projectId: "punktorders",
    storageBucket: "gymexercises-dfcd5.appspot.com",
    messagingSenderId: "1099018727241"
};
var fire = firebase.initializeApp(config);
export default fire;
