import React from 'react';
import './SendButton.css';

const SendButton = (props) => (
   
        <div 
        className={props.active ? "sendButton-active" : "sendButton-inactive"}
        onClick={props.onClick}>
            {props.english ? "Send" : "Siųsti"}
        </div>
  
);

export default SendButton;