import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacyPolicy-content">
        <div style={{ textAlign: "justify"}}>
            <h3>BENDRA INFORMACIJA</h3>
            <p>[imones_pavadinimas] (toliau – Duomenų valdytojas) šia privatumo politika (toliau – Privatumo politika) nustato asmens duomenų tvarkymo sąlygas naudojantis Duomenų valdytojo valdoma interneto svetaine. Privatumo politikos nustatytos sąlygos taikomos kiekvieną kartą jums norint pasiekti mūsų teikiamą turinį ir /ar paslaugą, nepaisant to, kokį įrenginį (kompiuterį, mobilųjį telefoną, planšetę, televizorių ar kt.) naudojate.
            </p>
            <h4>Duomenų valdytojo rekvizitai:</h4>
            <p style={{ textAlign: "center", fontWeight: "800"}}>[imones_pavadinimas]</p>
            <p style={{ textAlign: "center"}}>Įmonės kodas: [imones_kodas]</p>
            <p style={{ textAlign: "center"}}>Buveinės adresas: [buveines_adresas]</p>
            <p style={{ textAlign: "center"}}>Tel. [telefonas]</p>
            <p style={{ textAlign: "center"}}>El. paštas: [el_pastas]</p>
            <p>Labai svarbu, kad atidžiai perskaitytumėte Privatumo politiką, nes, kiekvieną kartą lankydami Duomenų valdytojui priklausančią interneto svetainę sutinkate su sąlygomis, aprašytomis šioje Privatumo politikoje. Jei nesutinkate su šiomis sąlygomis, prašome nesilankyti mūsų svetainėje, nesinaudoti mūsų turiniu ir/ar paslaugomis. Patvirtiname, kad Duomenų valdytojo svetainės lankytojų duomenys bus renkami laikantis galiojančių Europos Sąjungos bei Lietuvos Respublikos teisės aktų reikalavimų bei kontroliuojančių institucijų nurodymų. Taikomos visos protingos techninės ir administracinės priemonės tam, kad mūsų surinkti duomenys apie svetainės lankytojus būtų apsaugomi nuo praradimo, neleistino naudojimo ir pakeitimų. Asmenys, kurie yra jaunesni negu 16 metų, negali teikti jokių asmens duomenų per mūsų svetainę. Jei esate asmuo, kuris yra jaunesnis negu 16 metų, prieš pateikdami asmeninę informaciją, turite gauti savo tėvų arba kitų teisėtų globėjų sutikimą. Taisyklėse vartojamos sąvokos suprantamos taip, kaip jos yra apibrėžtos Bendrajame duomenų apsaugos reglamente Nr. 2016/679.</p>
            <h3>KOKIĄ INFORMACIJĄ APIE JUS RENKAME?</h3>
            <p>Tiesiogiai jūsų pateikiama informacija</p>
            <ul>
                <li>gimimo data,</li>
                <li>gyvenamoji vieta (adresas),</li>
                <li>lytis,</li>
                <li>vardas,</li>
                <li>pavardė,</li>
                <li>telefono numeris,</li>
                <li>elektroninio pašto adresas</li>
            </ul>
            <h4>Informacija, kaip naudojate mūsų svetaines.</h4>
            <p>Jeigu jūs lankotės mūsų interneto svetainėje, mes taip pat renkame informaciją, kuri atskleidžia mūsų teikiamų paslaugų naudojimo ypatumus arba automatiškai generuojama vizitų statistika. Daugiau apie tai skaitykite „Slapukai“.</p>
            <h4>Informacija iš trečiųjų šalių šaltinių</h4>
            <p>Mes galime gauti informacijos apie jus iš viešųjų ir komercinių šaltinių (kiek tai leidžia galiojantys teisės aktai) ir susieti ją su kita informacija, kurią gauname iš jūsų arba apie jus. Informacijos apie jus galime gauti ir iš trečiųjų šalių socialinių tinklų paslaugų, kai prie jų prisijungiate, pavyzdžiui, per paskyras “Facebook” tinkle.</p>
            <h4>Kita mūsų renkama informacija</h4>
            <p>Mes taip pat galime rinkti ir kitą informaciją apie jus, jūsų įrenginį ar jūsų naudojimąsi mūsų svetainės turiniu su jūsų sutikimu. Jūs galite rinktis nepateikti mums tam tikros informacijos (pvz., informacijos, prašomos pateikti registruojantis mūsų internetinėje parduotuvėje), tačiau tokiu atveju gali būti neleidžiama naudotis mūsų siūloma internetinio pirkimo paslauga.</p>
            <h3>KAIP NAUDOJAME JŪSŲ INFORMACIJĄ?</h3>
            <p>Galime naudoti surinktą informaciją toliau nurodytais tikslais:</p>
            <ul>
                <li>Užregistruoti jus tam, kad galėtumėte naudotis mūsų teikiama internetinio pirkimo paslauga.</li>
                <li>Pirkimo krepšelio apmokėjimui per pasirinktą išorinį bankinių paslaugų teikėją.</li>
                <li>Tiesioginės rinkodaros tikslu⃰, pvz., siųsti naujienlaiškius, pateikti individualiai pritaikytus reklaminius skelbimus ir siųsti pranešimus apie akcijas, įvertinti ir analizuoti savo rinką, klientus, produktus ir paslaugas (įskaitant ir jūsų nuomonės apie produktus ir paslaugas rinkimą bei klientų apklausų organizavimą).</li>
                <li>Sužinoti, kaip žmonės naudoja mūsų teikiamas internetines paslaugas, kad galėtume jas pagerinti ir kurti naują turinį, produktus bei paslaugas.</li>
                <li>Kitais būdais su jūsų sutikimu.⃰</li>
            </ul>
            <p>*Informuojame, kad jūs turite teisę nesutikti ar bet kuriuo metu atšaukti savo sutikimą tvarkyti jūsų duomenis aukščiau nurodytais, žvaigždute pažymėtais, tikslais.</p>
            <h3>KAM ATSKLEIDŽIAME JŪSŲ INFORMACIJĄ?</h3>
            <p>Įsipareigojame neperduoti jūsų asmeninių duomenų jokioms nesusijusioms trečiosioms šalims, išskyrus šiuos atvejus:</p>
            <ul>
                <li>jei yra jūsų sutikimas asmens duomenų atskleidimui.</li>
                <li>teikiant paslaugas – mūsų partneriams, teikiantiems prekių pristatymo ar kitas jūsų užsisakytas paslaugas. Šiems paslaugų tiekėjams mes suteiksime tik tiek jūsų asmeninės informacijos, kiek bus būtina konkrečiai paslaugai atlikti.</li>
                <li>teisėsaugos institucijoms pagal Lietuvos Respublikos teisės aktų numatytą tvarką.</li>
            </ul>
                <h3>KĄ DAROME, KAD APSAUGOTUME JŪSŲ INFORMACIJĄ?</h3>
                <p>Asmens duomenys yra saugomi nuo praradimo, neleistino naudojimo ir pakeitimų. Įdiegėme fizines ir technines priemones, kad apsaugotume visą informaciją, kurią renkame mūsų paslaugų teikimo tikslais. Primename, kad, nors ir imamės tinkamų veiksmų jūsų informacijai apsaugoti, nė viena svetainė, internetu atliekama operacija, kompiuterių sistema ar belaidis ryšys nėra visiškai saugūs. Jūsų duomenis saugosime 3 metus po paskutinio jūsų naudojimosi mūsų paslaugomis ar turiniu dienos. Pasibaigus šiam terminui, duomenys bus ištrinami taip, kad jų negalima būtų atgaminti.</p>
                <h3>JŪSŲ TEISĖS</h3>
                <p>Duomenų subjektas, kurio duomenys tvarkomi Duomenų valdytojo veikloje, turi šias teises:</p>
            <ul>
                <li>Žinoti (būti informuotas) apie savo duomenų tvarkymą (teisė žinoti);</li>
                <li>Susipažinti su savo duomenimis ir kaip jie yra tvarkomi (teisė susipažinti);</li>
                <li>Reikalauti ištaisyti arba, atsižvelgiant į asmens duomenų tvarkymo tikslus, papildyti asmens neišsamius asmens duomenis (teisė ištaisyti);</li>
                <li>Savo duomenis sunaikinti arba sustabdyti savo duomenų tvarkymo veiksmus (išskyrus saugojimą) (teisė sunaikinti ir teisė „būti pamirštam“);</li>
                <li>Turi teisę reikalauti, kad asmens Duomenų valdytojas apribotų asmens duomenų tvarkymą esant vienai iš teisėtų priežasčių (teisė apriboti);</li>
                <li>Turi teisę į duomenų perkėlimą (teisė perkelti);</li>
                <li>Nesutikti, kad būtų tvarkomi asmens duomenys, kai šie duomenys tvarkomi ar ketinami tvarkyti tiesioginės rinkodaros tikslais, įskaitant profiliavimą, kiek jis susijęs su tokia tiesiogine rinkodara. Jei nebenorite, kad jūsų asmens duomenys būtų tvarkomi tiesioginės rinkodaros tikslu, įskaitant profiliavimą, galite rašyti elektroninį laišką adresu, nurodytu šio teksto apačioje, arba skambinti telefonu irnesutikti, kad jūsų asmens duomenys būtų tvarkomi tiesioginės rinkodaros tikslu ir/arba kad nebūtų taikomas tik automatizuotas, įskaitant profiliavimą**, jūsų asmens duomenų tvarkymas, nenurodydami nesutikimo motyvų.</li>
            </ul>
            <p>**Pagal jūsų pateiktus asmens duomenis, tiesioginės rinkodaros tikslu, gali būti vykdomas jūsų asmens duomenų profiliavimas,siekiant pasiūlyti jums individualiai pritaikytus sprendimus ir pasiūlymus. Jūs bet kada galite atšaukti savo sutikimą tvarkyti asmens duomenis automatizuotu, įskaitant profiliavimą, tvarkymobūduarba tam prieštarauti. Bet kokį prašymą ar nurodymą, susijusį su asmens duomenų tvarkymu, Duomenų subjektas turi teisę pateikti Duomenų valdytojui raštu vienu iš šių būdų: įteikiant tiesiogiai adresu; paštu; el. paštu, nurodytu šio teksto apačioje. Duomenų valdytojas gavęs tokį prašymą ar nurodymą, ne vėliau kaip per vieną mėnesį nuo kreipimosi dienos, pateikia atsakymą bei atlieka prašyme nurodytus veiksmus ar atsisako juos atlikti. Prireikus nurodytas laikotarpis gali būti pratęstas dar dviem mėnesiams, atsižvelgiant į prašymų sudėtingumą ir skaičių. Tokiu atveju, per vieną mėnesį nuo prašymo gavimo dienos Duomenų valdytojas informuoja Duomenų subjektą apie tokį pratęsimą, kartu pateikdamas vėlavimo priežastis. Duomenų valdytojas gali nesudaryti duomenų subjektams sąlygų įgyvendinti aukščiau išvardintų teisių, išskyrus nesutikimą tvarkyti asmens duomenis tiesioginės rinkodaros būdu, kai įstatymų numatytais atvejais, reikia užtikrinti nusikaltimų, tarnybinės ar profesinės etikos pažeidimų prevenciją, tyrimą ir nustatymą, taip pat duomenų subjekto ar kitų asmenų teisių ir laisvių apsaugą.</p>
            <h3>TREČIŲJŲ ŠALIŲ SVETAINĖS, PASLAUGOS IR PRODUKTAI MŪSŲ SVETAINĖSE</h3>
            <p>Duomenų valdytojo interneto svetainėjegali būti trečiųjų šalių reklaminių skydelių, nuorodų į jų svetaines ir paslaugas, kurių Duomenų valdytojas nekontroliuoja. Duomenų valdytojas neatsako už informacijos, kurią surinko trečiosios šalys, saugą ir privatumą. Turite perskaityti privatumo nuostatas, taikomas trečiųjų šalių svetainėms ir paslaugoms, kuriomis naudojatės.</p>
            <h3>SLAPUKAI</h3>
            <p>Jums besilankant Duomenų valdytojo interneto svetainėje, norime pateikti tokį turinį ir funkcijas, kurios būtų pritaikytos būtent jūsų poreikiams. Tam reikalingi slapukai (angl. cookies). Tai nedideli informacijos elementai, kurie automatiškai sukuriami naršant svetainėje ir yra išsaugomi jūsų kompiuteryje ar kitame galiniame įrenginyje. Jie padeda Duomenų valdytojui atpažinti jus kaip ankstesnį tam tikros interneto svetainės lankytoją, išsaugoti jūsų lankymosi svetainėje istoriją ir pagal tai pritaikyti turinį. Taip pat slapukai padeda užtikrinti sklandų svetainių veikimą, leidžia stebėti lankymosi interneto svetainėse trukmę, dažnumą bei rinkti statistinę informaciją apie svetainių lankytojų skaičių. Mūsų svetainėje naudojamų slapukų aprašymai</p>
            <table>
                <tr>
                    <th style={{ width: "20%"}}>Slapuko pavadinimas</th>
                    <th style={{ width: "20%"}}>Galiojimo laikas</th>
                    <th style={{ width: "60%"}}>Naudojami duomenys</th>
                </tr>

                <tr>
                    <td>_ga</td>
                    <td>2m.</td>
                    <td>Vartotojo išskyrimas</td>
                </tr>

                <tr>
                    <td>pll_language</td>
                    <td>1 m.</td>
                    <td>Naudojamas saugoti vartotojo pasirinktą kalbą</td>
                </tr>
            </table>
            <h4>Kaip valdyti ir ištrinti slapukus</h4>
            <p>Kai naudojate naršyklę mūsų teikiamam turiniui pasiekti, galite konfigūruoti savo naršyklę taip, kad būtų priimti visi slapukai, atmesti visi slapukai arba būtų pranešama, kai slapukas atsiunčiamas. Kiekviena naršyklė skirtinga, todėl, jei nežinote, kaip pakeisti slapukų nuostatas, pasižiūrėkite jos žinyno meniu. Jūsų įrenginio operacinėje sistemoje gali būti papildomų slapukų valdiklių. Jei nenorite, kad informacija būtų renkama slapukų pagalba, pasinaudokite daugelyje naršyklių esama paprasta procedūra, kuri leidžia jums atsisakyti slapukų naudojimosi. Norėdami daugiau sužinoti, kaip valdyti slapukus, apsilankykite adresu: http://www.allaboutcookies.org/manage-cookies/. Tačiau atkreipiame dėmesį, kad kai kuriais atvejais slapukų ištrynimas gali sulėtinti naršymo internete spartą, apriboti tam tikrų interneto svetainės funkcijų veikimą arba blokuoti prieigą prie svetainės. Mūsų svetainėje gali būti nuorodų į kitų asmenų, įmonių ar organizacijų interneto tinklalapius. Atkreipiame dėmesį, kad Duomenų valdytojas nėra atsakingas už tokių interneto tinklalapių turinį ar jų naudojamus privatumo užtikrinimo principus. Tad jei paspaudę nuorodą iš Duomenų valdytojo interneto svetainės pateksite į kitus tinklalapius, turėtumėte atskirai pasidomėti jų Privatumo politika.</p>
            <h3>SUSISIEKITE SU MUMIS</h3>
            <p>Jei dėl šioje Privatumo politikoje pateiktos informacijos jums iškiltų klausimų, maloniai prašome kreiptis bet kuriuo jums patogiu būdu:</p>
            <ul>
                <li>Paštu: [korespondencijos_adresas]</li>
                <li>Telefonu: [telefonas]</li>
                <li>El. paštu: [el_pastas]</li>
            </ul>
            <p>Atnaujinta [data]</p>
        </div>
    </div>
  );
}

export default PrivacyPolicy;
