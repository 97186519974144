import React, { Component } from 'react';
import './ContactForm.css';
import TextField from '@material-ui/core/TextField';
import SendButton from '../Buttons/SendButton';
import fire from '../../Firebase/Firebase';

class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            nameError: false,
            emailError: false,
            phoneError: false,
            messageError: false,
            messageSent: false,

        };
    }

    handleNameInput = (text) => {
        if (text.length > 100) {
            this.setState({nameError: true});
        }
        else {
            this.setState({name: text});
            this.setState({nameError: false});
        }
    };

    handleEmailInput = (text) => {
        if (text.length > 100) {
            this.setState({emailError: true});
        } else {
            let reg = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/
                if ( reg.test(text) === false && text !== "") {
                    this.setState({email: text});
                    this.setState({emailError:true});
            } else {
                    this.setState({email: text});
                    this.setState({emailError: false});
            }
        }
    }

    handlePhoneInput = (text) => {
        if (text.length > 100) {
            this.setState({phoneError: true});
        } else {
            let reg = /^[+-.0-9 ]+$/
                if ( reg.test(text) === false && text !== "") {
                    this.setState({phone: text});
                    this.setState({phoneError:true});
            } else {
                    this.setState({phone: text});
                    this.setState({phoneError: false});
            }
        }
    }

    handleMessageInput = (text) => {
        if (text.length > 1000) {
            this.setState({messageError: true});
        }
        else {
            this.setState({message: text});
            this.setState({messageError: false});
        }
    };

    sendMessage = () => {
        this.setState({messageSent: true});
        this.setState({name: ''});
        this.setState({email: ''});
        this.setState({phone: ''});
        this.setState({message: ''});

        let message = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            message: this.state.message,
        }
        fire.database().ref("messages").push(message);
    }

  
    render() {

        let sendButton;

        if (this.state.messageSent === false && (this.state.name !== '' && this.state.email !== '' && this.state.message !== ''
            && this.state.nameError === false && this.state.emailError === false
            && this.state.phoneError === false && this.state.messageError === false)) {
                sendButton = <SendButton 
                active={true} 
                onClick={this.sendMessage}
                english={this.props.english}
                />        
            } 
        if (this.state.messageSent && this.props.english) {
            sendButton = <div className="contactForm-sentMessage">Thank's! I'll get back to you soon. Justis :) </div>
        }

        if (this.state.messageSent && !this.props.english) {
            sendButton = <div className="contactForm-sentMessage">Ačiū! Greit su jumis susisieksiu. Justis :) </div>
        }
            
        if (this.state.messageSent === false && (this.state.name === '' || this.state.email === '' || this.state.message === ''
        || this.state.nameError === true || this.state.emailError === true
        || this.state.phoneError === true || this.state.messageError === true)) {
            sendButton = <SendButton 
                            active={false} 
                            english={this.props.english}
                            />
        }

        let contactForm;

        if (this.props.english) {
            contactForm = (
                <div className="contactForm-container">
                <div className="contactForm-title"><h2>Contact</h2></div>
                <div className="contactForm-items">
                <TextField
                error={this.state.nameError}
                style={{width:'100%'}}
                id="outlined-name"
                label="Your Name"
                onChange={(event) => this.handleNameInput(event.target.value)}
                className=""
                value={this.state.name}
                margin="normal"
                variant="outlined"
                                    />
                <TextField
                error={this.state.emailError}
                style={{width:'100%'}}
                id="outlined-name"
                label="Your e-mail"
                onChange={(event) => this.handleEmailInput(event.target.value)}
                className=""
                value={this.state.email}
                margin="normal"
                variant="outlined"
                                    />
                <TextField
                error={this.state.phoneError}
                style={{width:'100%'}}
                id="outlined-name"
                label="Your Telephone Number"
                onChange={(event) => this.handlePhoneInput(event.target.value)}
                className=""
                value={this.state.phone}
                margin="normal"
                variant="outlined"
                                    />
               <TextField
                error={this.state.messageError}
                style={{width:'100%'}}
                id="outlined-name"
                label="Your Message"
                onChange={(event) => this.handleMessageInput(event.target.value)}
                className=""
                rows="4"
                multiline
                value={this.state.message}
                margin="normal"
                variant="outlined"
                                    />
                {sendButton}
                </div>                  
            </div>
            )
        } else {
            contactForm = (
                <div className="contactForm-container">
                <div className="contactForm-title"><h2>Susisiekite</h2></div>
                <div className="contactForm-items">
                <TextField
                error={this.state.nameError}
                style={{width:'100%'}}
                id="outlined-name"
                label="Jūsų vardas"
                onChange={(event) => this.handleNameInput(event.target.value)}
                className=""
                value={this.state.name}
                margin="normal"
                variant="outlined"
                                    />
                <TextField
                error={this.state.emailError}
                style={{width:'100%'}}
                id="outlined-name"
                label="Jūsų e-paštas"
                onChange={(event) => this.handleEmailInput(event.target.value)}
                className=""
                value={this.state.email}
                margin="normal"
                variant="outlined"
                                    />
                <TextField
                error={this.state.phoneError}
                style={{width:'100%'}}
                id="outlined-name"
                label="Jūsų telefono numeris"
                onChange={(event) => this.handlePhoneInput(event.target.value)}
                className=""
                value={this.state.phone}
                margin="normal"
                variant="outlined"
                                    />
               <TextField
                error={this.state.messageError}
                style={{width:'100%'}}
                id="outlined-name"
                label="Jūsų žinutė"
                onChange={(event) => this.handleMessageInput(event.target.value)}
                className=""
                rows="4"
                multiline
                value={this.state.message}
                margin="normal"
                variant="outlined"
                                    />
                {sendButton}
                </div>            
            </div>
            )
        }


        return (
            <div>
                {contactForm}
            </div>
        );
    }
}

export default ContactForm;