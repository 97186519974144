import React, { Component } from 'react';
import './Main.css';
import punkt from '../assets/punkt.png';
import Websites from './Content/Websites';
import Apps from './Content/Apps';
import Design from './Content/Design';
import Features from './Features/Features';
import ContactForm from './ContactForm/ContactForm';
import Contact from './Notifications/Contact';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import CookieConsent from "react-cookie-consent";

class Main extends Component {
    constructor(props) {
        super(props);
        this.web = React.createRef();
        this.ui = React.createRef();
        this.mobile = React.createRef();
        this.features = React.createRef();
        this.state = {
            english: true,
            privacyPolicy: false
        };
    }

    scrollToWeb = () => {
        window.scrollTo(0, this.web.current.offsetTop);
    }

    scrollToUI = () => {
        window.scrollTo(0, this.ui.current.offsetTop);
    }

    scrollToMobile = () => {
        window.scrollTo(0, this.mobile.current.offsetTop);
    }

    scrollToFeatures = () => {
        window.scrollTo(0, this.features.current.offsetTop);
    }

    toggleLanguage = () => {
        if (this.state.english) {
            this.setState({english:false});
        } else {
            this.setState({english:true});
        }
    }

    toggleCookiePolicy = () => {
        if (this.state.privacyPolicy) {
            console.log("privacyPolicy clicked");
            this.setState({privacyPolicy:false});
        } else {
            console.log("privacyPolicy clicked");
            this.setState({privacyPolicy:true});
        }
    }
        
    render() {

        let content;

        if (this.state.english && this.state.privacyPolicy === false) {
            content = (
                <div>
                 <div className="mainTitleName" >
                    <div className="main-logoContainer">
                        <img src={punkt} alt="punkt"/>
                    </div>
                    </div>
                    <div className="mainTitle"><h1>web and mobile apps</h1></div>
                        <div className="main-menuContainer">
                            <div className="main-menuItem" onClick={() => this.scrollToWeb()}>Web apps</div>
                            <div className="main-menuItem" onClick={() => this.scrollToUI()}>UI</div>
                            <div className="main-menuItem" onClick={() => this.scrollToMobile()}>Mobile apps</div>
                            <div className="main-menuItem" onClick={() => this.scrollToFeatures()}>Features</div>
                            <div className="main-menuItemLanguage" onClick={() => this.toggleLanguage()}>Lietuviškai</div>
                        </div>
                    <div className="main-notificationContainer">     
                        <Contact
                            english={this.state.english}
                        />
                    </div>
                    <div ref={this.web}></div>
                    <Websites 
                        english={this.state.english}
                    />
                    <div ref={this.ui}></div>
                    <Apps
                        english={this.state.english}
                        />
                    <div ref={this.mobile}></div>
                    <Design
                        english={this.state.english}
                        />
                    <div ref={this.features}></div>
                    <Features
                        english={this.state.english}
                    />
                    <ContactForm
                        english={this.state.english}
                    />
                    <CookieConsent
                        location="bottom"
                        buttonText="Sure man!"
                        cookieName="myAwesomeCookieName2"
                        enableDeclineButton
                        style={{ background: "#f2f2f2", color: "#54595f" }}
                        buttonStyle={{ color: "#035fa0", fontSize: "14px", fontWeight: "700" }}
                        expires={150}
                    >
                        This website uses cookies to enhance the user experience.{" "}
                    <span 
                        onClick = {() => this.toggleCookiePolicy()} 
                        style={{ fontSize: "10px", cursor: "pointer", textDecoration: "underline" }}>
                            View privacy policy
                    </span>
                    </CookieConsent>
                </div>
            )
        }
        if (this.state.english === false && this.state.privacyPolicy === false) {
            content = (
                <div>
                <div className="mainTitleName" >
                   <div className="main-logoContainer">
                       <img src={punkt} alt="punkt"/>
                   </div>  
                   </div>
                   <div className="mainTitle"><h1>tinklinės ir mobiliosios aplikacijos</h1></div>
                       <div className="main-menuContainer">
                            <div className="main-menuItem" onClick={() => this.scrollToWeb()}>Tinklinės</div>
                            <div className="main-menuItem" onClick={() => this.scrollToUI()}>GS</div>
                            <div className="main-menuItem" onClick={() => this.scrollToMobile()}>Mobiliosios</div>
                            <div className="main-menuItem" onClick={() => this.scrollToFeatures()}>Privalumai</div>
                            <div className="main-menuItemLanguage" onClick={() => this.toggleLanguage()}>English</div>
                       </div>
                    <div className="main-notificationContainer">     
                        <Contact
                            english={this.state.english}
                        />
                    </div>
                    <div ref={this.web}></div>
                    <Websites 
                        english={this.state.english}
                    />
                    <div ref={this.ui}></div>
                    <Apps
                        english={this.state.english}
                    />
                    <div ref={this.mobile}></div>
                    <Design
                        english={this.state.english}
                    />
                    <div ref={this.features}></div>
                    <Features
                        english={this.state.english}
                    />
                    <ContactForm
                        english={this.state.english}
                    />
                    <CookieConsent
                        location="bottom"
                        buttonText="Tai gerai!"
                        cookieName="myAwesomeCookieName2"
                        enableDeclineButton
                        style={{ background: "#f2f2f2", color: "#54595f" }}
                        buttonStyle={{ color: "#035fa0", fontSize: "14px", fontWeight: "700" }}
                        expires={150}
                    >
                        Šis tinklalapis naudoja slapukus (sausainiukus).{" "}
                        <span 
                            onClick = {() => this.toggleCookiePolicy()} 
                            style={{ fontSize: "10px", cursor: "pointer", textDecoration: "underline" }}>
                                Žiūrėti privatumo politiką
                        </span>
                    </CookieConsent>
                </div>
            )
        }
        if (this.state.english && this.state.privacyPolicy) {
            content = (
                <div>
                    <div 
                        className = "privacyPolicy-returnButton"
                        onClick = {() => this.toggleCookiePolicy()}>
                        Return
                        
                    </div>
                    <PrivacyPolicy/>
                    <div 
                        className = "privacyPolicy-returnButton"
                        onClick = {() => this.toggleCookiePolicy()}>
                        Return
                        
                    </div>
                </div>)
        }

        if (this.state.english === false && this.state.privacyPolicy) {
            content = (
                <div>
                    <div 
                        className = "privacyPolicy-returnButton"
                        onClick = {() => this.toggleCookiePolicy()}>
                        Grįžti
                    </div>
                    <PrivacyPolicy/>
                    <div 
                        className = "privacyPolicy-returnButton"
                        onClick = {() => this.toggleCookiePolicy()}>
                        Grįžti
                    </div>
                </div>)
        }
      
        return (
            <div>
            {content}
            </div>
        );
    }
}

export default Main;