import React from 'react'
import { useSpring, animated } from 'react-spring';
import poster1 from '../../assets/poster1_punkt_spacetime.png';

const calc = (x, y) => [-(y - window.innerHeight / 2) / 240, (x - window.innerWidth / 2) / 240, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const Image1 = (props) => {
  const [image, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 12, tension: 357, friction: 97 } }))
  return (
    <animated.div
      className="image"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: image.xys.interpolate(trans) }}
    >
        <img src={poster1} alt="spacetime"/>
    </animated.div>
  )
}

export default Image1;
